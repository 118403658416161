
// Vue
import { computed, ref, watch } from 'vue';
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";

// icons
import { logOutOutline, createOutline, keyOutline, camera, globe, moon, add,
          heart, heartOutline, eyeOutline, logoWhatsapp, star, } from 'ionicons/icons';

// components
import { IonPage, IonContent, IonGrid, IonRow, IonCol,
        IonSpinner, IonList, IonListHeader, IonItem, IonLabel,
        IonIcon, IonBackButton, IonButton, IonButtons, IonNote, IonImg,
        IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonInput,
        IonToggle, IonSelect, IonSelectOption, IonBadge, IonChip, IonFabButton,
        alertController, toastController, modalController, loadingController, } from '@ionic/vue';
import UserProfileFormModal from '@/components/modals/UserProfileFormModal.vue';
import SchoolSelectModal from '@/components/secondary/SchoolSelectModal.vue';

// API services
import AuthService from '@/services/AuthService';
import UserService from '@/services/UserService';

// utils or methods
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';

import config from '@/config';
import { utils } from '@/composables/utils';
import PortalService from '@/services/PortalService';

export default {
  name: 'UserProfile',
  components: { IonPage, IonContent, IonGrid, IonRow, IonCol,
                IonSpinner, IonList, IonListHeader, IonItem, IonLabel,
                IonIcon, IonBackButton, IonButton, IonButtons, IonNote, IonImg,
                IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonInput,
                IonToggle, IonSelect, IonSelectOption, IonBadge, IonChip, IonFabButton,
                alertController, toastController, modalController, loadingController, },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { formatStudentNumber, sleep, getAppSheetFileLink, } = utils();
    
    // 1. declare state variables (ref to make them reactive)
    const loading = computed(() => store.state.loadingUser);
    const user = computed(() => store.state.user);
    const schools = computed(() => store.state.schools);
    const isAB4User = computed(() => store.getters.isAB4User);

    // jobEX / university
    const jobEXes = computed(() => store.state.allJobEXes);
    const allClients = computed(() => store.state.allClients);
    
    // methods or filters
    const { t, locale } = useI18n();

    const doLogout = async () => {
      const alert = await alertController.create({
        header: t('UserProfilePage.logout'),
        message: t('UserProfilePage.confirmLogout'),
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          }, {
            text: t('UserProfilePage.logout'),
            handler: () => {
              if (store.state.onetimeAccessInfo) {
                localStorage.removeItem("sessionAccessToken");
                store.commit('receiveOnetimeAccessInfo', null);
                store.dispatch('resetUserData');
                //router.replace('/home');
              }
              AuthService.doLogout();
              router.replace('/login'); // redirect to login page
            }
          }
        ]
      });
      return alert.present();
    }

    const presentToast = async(msg: string, duration = 5000) => {
      const toast = await toastController.create({
        message: msg,
        duration,
        position: 'top',
      });
      toast.present();
    }
    const presentChangePasswordPrompt = async () => {
      const alert = await alertController.create({
        header: t('UserProfilePage.changePassword'),
        inputs: [
          {
            name: 'oldPassword',
            type: 'password',
            placeholder: t('UserProfilePage.oldPassword')
          },
          {
            name: 'newPassword',
            type: 'password',
            placeholder: t('UserProfilePage.newPassword')
          },
          {
            name: 'newPasswordConfirm',
            type: 'password',
            placeholder: t('UserProfilePage.newPasswordConfirm')
          },
        ],
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          }, {
            text: t('confirm'),
            handler: (value) => {
              if (value.oldPassword && value.newPassword && value.newPasswordConfirm) {
                if (value.newPassword != value.newPasswordConfirm) {
                  presentToast(t('inconsistentNewPasswordMsg'));
                } else {
                  AuthService.updatePassword(value.oldPassword, value.newPassword).then(res => {
                    if (res == 'wrong-password') {
                      presentToast(t('wrongOldPassword'));
                    } else {
                      presentToast(t('successUpdatePassword'), 3000);
                      alert.dismiss();
                    }
                  });
                }
              } else {
                presentToast(t('enterAllFields'));
              }
              return false;
            }
          }
        ]
      });
      await alert.present();
    }
    
    // update user information
    const openEditUserProfileModal = async () => {
      const modal = await modalController.create({
        component: UserProfileFormModal,
      });
      return modal.present();
    }

    // Update user profile picture (Capacitor Camera)
    const updateProfilePic = async () => {
      const cameraPhoto = await Camera.getPhoto({
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Prompt,
        quality: 60,
        width: 1500,
      });
      const loading = await loadingController.create({});
      await loading.present();
      const fileName = `${moment().format('YYYYMMDDHHmmss')}-${user.value.id}.${cameraPhoto.format}`;
      const res = await UserService.updateLoggedInUserProfilePic(cameraPhoto.dataUrl, fileName, user.value.profilePic);
      loading.dismiss();
      presentToast(t("UserProfilePage.successChangeProfilePhoto"));
      store.commit('updateUser', { profilePic: res.profilePic });
    }

    const toggleDarkTheme = async (enableDarkTheme: boolean) => {
      document.body.classList.toggle('dark', enableDarkTheme);
      if (user.value.id) { // only for logged in users
        const currDark: any = user.value.darkTheme;
        if ((currDark == true && !enableDarkTheme) || ((currDark == false || currDark == '') && enableDarkTheme)) {
          const loading = await loadingController.create({});
          await loading.present();
          UserService.updateUserDarkThemeSetting(enableDarkTheme);
          document.body.classList.toggle('dark', enableDarkTheme);
          store.commit('updateUser', { darkTheme: enableDarkTheme });
          await sleep(1);
          loading.dismiss();
        }
      }
    }

    const updateUserSchool = async () => {
      const loading = await loadingController.create({});
      await loading.present();
      const { schoolId } = user.value;
      const latestSchoolIntake = store.state.absIntakes.find(a => a.schoolId == schoolId);
      const updatingObj = { schoolId, absIntakeId: latestSchoolIntake ? latestSchoolIntake.id : "" };
      UserService.updateUser(updatingObj);
      store.commit('updateUser', updatingObj);
      store.dispatch('getStep1Questions'); // mainly for AB4 (assume Portal Data already fetched)
      store.dispatch('getSchoolUsers', schoolId);
      loading.dismiss();
    }

    let schoolSelectModalOpened = false;

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      logOutOutline, createOutline, keyOutline, camera, globe, moon, add,
      heart, heartOutline, eyeOutline, logoWhatsapp, star,

      // variables
      locale,
      loading, user, schools,
      versionCode: config.versionCode,

      // methods
      formatStudentNumber, getAppSheetFileLink,
      t, toggleDarkTheme, doLogout, presentChangePasswordPrompt, openEditUserProfileModal,
      updateProfilePic, updateUserSchool,
      updateUserLocale: async () => {
        if (user.value.id) {
          if (user.value.preferredLang != locale.value) {
            UserService.updateUserAppLanguage(locale.value);
            store.commit('updateUser', { preferredLang: locale.value });
          }
        }
      },

      openSchoolSelectModal: async () => {
        if (!schoolSelectModalOpened) {
          schoolSelectModalOpened = true;
          const modal = await modalController.create({
            component: SchoolSelectModal,
            componentProps: { schools: schools.value, showAllSchools: true }
          });
          modal.onDidDismiss().then(({ data }) => {
            if (data && data.selectedSchool) {
              user.value.schoolId = data.schoolId || data.selectedSchool;
              updateUserSchool();
            }
            schoolSelectModalOpened = false;
          });
          return modal.present();
        }
      },
      onClickWhatsAppButton: () => {
        store.dispatch('setUserJoinedWAGroup');
      },

      // Is / is not AB4 school
      isAB4User,
      toggleUserIsAB4School: (isAB4School: boolean) => {
        if (isAB4School) {
          const { schoolId } = user.value;
          const latestSchoolIntake = store.state.absIntakes.find(a => a.schoolId == schoolId) ||
                                      store.state.absIntakes.find(a => a.sessions.length > 0);
          store.commit('updateUser', {
            absIntakeId: latestSchoolIntake ? latestSchoolIntake.id : "",
            isAB4User: true,
          }); // AB4 School view
        } else {
          store.commit('updateUser', { absIntakeId: "", isAB4User: false, }); // Non-AB4 School view
        }
      },

      // Switch between student & teachers
      updateUserAppRole: () => {
        let baseParams = { teacher: null, isSecondaryStudent: false, isUniversityStudent: false, isUniversityClient: false };
        if (user.value.appRole == 'teacher') {
          store.commit('updateUser', {
            ...baseParams,
            teacher: store.state.teacher,
          }); // Teacher view
          store.dispatch('getSchoolUsers', user.value.schoolId);
        } else {
          switch (user.value.appRole) {
            case 'secondary-school-student':
              baseParams = { ...baseParams, isSecondaryStudent: true }; // Student view
              break;
            case 'university-student':
              baseParams = { ...baseParams, isUniversityStudent: true };
              //store.dispatch('getProgramUsers', user.value.programId);
              break;
            case 'university-client':
              baseParams = { ...baseParams, isUniversityClient: true };
              store.dispatch('getProgramUsers', user.value.programId); // TBC: can browse list of university students
              break;
          }
          store.commit('updateUser', baseParams);
        }
        localStorage.setItem(config.sessionAppRoleField, user.value.appRole);
      },

      // jobEX / University
      jobEXes,
      updateUserProgram: async () => {
        const loading = await loadingController.create({});
        await loading.present();
        const { programId } = user.value;
        store.dispatch('getStep1Questions', programId);
        store.dispatch('getUniProgramData', programId);
        store.dispatch('getProgramUsers', user.value.programId);
        UserService.updateUser({ programId });
        store.commit('updateUser', { programId });
        loading.dismiss();
      },
      allClients,
      updateUserClient: async () => {
        const loading = await loadingController.create({});
        await loading.present();
        const { clientId, programId: oldProgramId, } = user.value;
        const client: any = allClients.value.find(c => c.id == clientId) || {};
        const jobEXObj = jobEXes.value.find(jobEX => jobEX['jobEX Name'] == client.jobEXName);
        const programId = jobEXObj?.relatedProgramIds[0]; // updated program ID
        if (programId) {
          store.dispatch('getStep1Questions', programId);
          store.dispatch('getUniProgramData', programId);
          store.dispatch('getProgramUsers', programId);
        }
        UserService.updateUser({ clientId, programId: programId || oldProgramId });
        store.commit('updateUser', { clientId, programId: programId || oldProgramId });
        loading.dismiss();
      }
    }
  },
}
